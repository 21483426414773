import React from "react";
import PropTypes from "prop-types";
import { Reveal, Tween } from "react-gsap";
import SmartText from "../../utils/TextHandler";
import Link from "../Global/Link";
import SmartImage from "../../utils/ImageHandler";
import Image from "../Global/Image";
import SocialLinks from "../Global/SocialLinks";
import Panel from "../Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionItem = (props) => {
  const { item, oddEven } = props;

  return (
    <section>
      <div className="container">
        <div
          className={`row justify-content-center align-items-center py-0 py-md-5 mb-5 mb-md-0 ${
            oddEven === "odd" ? "flex-row-reverse" : ""
          }`}
        >
          <Reveal>
            <Tween
              from={{
                x: `${oddEven === "odd" ? "50px" : "-50px"}`,
                opacity: 0,
              }}
              duration={1}
            >
              <div className="col-12 col-md-6 col-lg-5 mb-3 mb-md-0">
                <Image
                  src={item.image ? item.image.filename : ""}
                  sm="576x0"
                  md="448x0"
                  lg="816x0"
                  alt={item.title && item.title}
                  className="mw-100 brand-radius"
                />
              </div>
            </Tween>
            <Tween
              from={{
                x: `${oddEven === "odd" ? "-50px" : "50px"}`,
                opacity: 0,
              }}
              duration={1}
            >
              <div className="col-12 col-md-6 col-lg-5">
                {item.title && <h3 className="fw-bold">{item.title}</h3>}
                {item.body && <SmartText>{item.body}</SmartText>}
              </div>
            </Tween>
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default SectionItem;
