import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Reveal, Tween } from "react-gsap";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SmartColour from "../../utils/ColourHandler";
import SocialLinks from "../Global/SocialLinks";
import Title from "../Title/Title";
import CardGame from "../Cards/CardGame";

const SectionGames = (props) => {
  const { item } = props;

  const colour = SmartColour(item.colour);

  return (
    <section
      className={`py-5 brand-background-${
        colour.background ? colour.background : "white"
      }`}
    >
      <div className="container">
        <Title
          background={colour.background}
          text={colour.text}
          accent={colour.accent}
          title={item.title}
          description={item.description}
          button={item.button}
          link={item.link}
        />
        <div className="row gy-4 justify-content-start">
          <>
            {item.games.map((node, index) => {
              const game = node.game;
              const delay = (1 * index) / 10;

              return (
                <Tween
                  from={{ x: "300px", opacity: 0 }}
                  duration={0.75}
                  delay={delay}
                  ease="back.out(1.5)"
                >
                  <div className="col-6 col-md-3">
                    <CardGame
                      item={game}
                      sm="228x228"
                      md="192x192"
                      lg="350x350"
                    />
                  </div>
                </Tween>
              );
            })}
          </>
        </div>
      </div>
    </section>
  );
};

export default SectionGames;
