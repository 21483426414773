import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SocialLinks from "../Global/SocialLinks";
import Title from "../Title/Title";
import CardNews from "../Cards/CardNews";
import CardImageTest from "../Cards/CardImageTest";

const SectionNews = (props) => {
  const { item } = props;

  const newsPosts = useStaticQuery(graphql`
    {
      newsPosts: allStoryblokEntry(
        filter: { field_component: { in: ["news_post"] } }
        sort: {
          fields: [field_featured_boolean, field_date_string]
          order: [DESC, DESC]
        }
        limit: 3
      ) {
        nodes {
          field_component
          field_date_string
          full_slug
          lang
          content
        }
      }
    }
  `);

  let news;
  if (newsPosts) {
    news = newsPosts.newsPosts;
  }

  return (
    <>
      {news && (
        <section className="py-5 pb-0">
          <div className="container">
            <Title
              title={item.title}
              description={item.description}
              button={item.button}
              link="/news/"
            />

            <div className="row justify-content-between mb-5">
              <div className="col-12 col-md-6 col-lg-6">
                {news.nodes.map((node, index) => {
                  const item = JSON.parse(node.content);
                  if (index === 0) {
                    return (
                      <CardNews
                        item={item}
                        size="lg"
                        preview
                        border
                        link={node.full_slug}
                      />
                    );
                  }
                })}
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                {news.nodes.map((node, index) => {
                  const item = JSON.parse(node.content);
                  if (index > 0) {
                    return (
                      <CardNews
                        item={item}
                        size="sm"
                        preview
                        border={index >= 4 ? false : true}
                        link={node.full_slug}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SectionNews;
