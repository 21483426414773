import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SmartTag from "../../utils/TagHandler";
import Link from "../Global/Link";
import Image from "../Global/Image";
import Moment from "react-moment";

const CardImageTest = (props) => {
  const { item, size } = props;

  return (
    <div className="overflow-hidden">
      <Image
        src={item.image.filename}
        sm="100x100"
        md="400x100"
        lg="100x300"
        alt={item.title}
        className="border"
      />
    </div>
  );
};

export default CardImageTest;

CardImageTest.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardImageTest.defaultProps = {
  settings: null,
};
