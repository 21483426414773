import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Reveal, Tween } from "react-gsap";
import SmartText from "../../utils/TextHandler";
import SmartTag from "../../utils/TagHandler";
import Image from "../Global/Image";
import PlatformLinks from "../Global/PlatformLinks";
import SmartImage from "../../utils/ImageHandler";
import Link from "../Global/Link";
import SocialLinks from "../Global/SocialLinks";
import Title from "../Title/Title";
import CardGame from "../Cards/CardGame";

function oddOrEven(x) {
  return x & 1 ? "odd" : "even";
}

const SectionGamesFeatured = (props) => {
  const { item } = props;

  return (
    <section className="pt-3 pt-md-5 brand-background-white">
      <div className="container">
        {item.games.map((node, index) => {
          const game = node.game;
          const oddEven = oddOrEven(index);
          return (
            <>
              {game && game.content && (
                <Reveal>
                  <div
                    className={`row justify-content-center align-items-center mb-5 position-relative ${
                      oddEven === "odd" ? "flex-row-reverse" : ""
                    }`}
                  >
                    <Tween
                      from={{
                        x: `${oddEven === "odd" ? "50px" : "-50px"}`,
                        opacity: 0,
                      }}
                      duration={1}
                    >
                      <div className="col-12 col-md-7 mb-3 mb-md-0">
                        <Image
                          src={
                            game && game.content.image
                              ? game.content.image.filename
                              : ""
                          }
                          sm="576x0"
                          md="448x0"
                          lg="816x0"
                          alt={game && game.content.title && game.content.title}
                          className="w-1000 brand-radius brand-shadow"
                        />
                      </div>
                    </Tween>
                    <div className="col-12 col-md-5">
                      {game && game.content.lifecycle && (
                        <Tween
                          from={{ y: "100px", opacity: 0 }}
                          duration={0.75}
                          delay={0}
                          ease="back.out(1.5)"
                        >
                          <div className="mb-3">
                            <span className="badge brand-background-pink">
                              {SmartTag(game.content.lifecycle)}
                            </span>
                          </div>
                        </Tween>
                      )}
                      {game && game.content.title && (
                        <Tween
                          from={{ y: "100px", opacity: 0 }}
                          duration={0.75}
                          delay={0.25}
                          ease="back.out(1.5)"
                        >
                          <h3 className="fw-bold">{game.content.title}</h3>
                        </Tween>
                      )}
                      {game && game.content.preview && (
                        <Tween
                          from={{ y: "100px", opacity: 0 }}
                          duration={0.75}
                          delay={0.5}
                          ease="back.out(1.5)"
                        >
                          <div>
                            <SmartText className="d-block">
                              {game.content.preview}
                            </SmartText>
                          </div>
                        </Tween>
                      )}
                      {game && game.content.platforms && (
                        <Tween
                          from={{ y: "100px", opacity: 0 }}
                          duration={0.75}
                          delay={0.75}
                          ease="back.out(1.5)"
                        >
                          <div>
                            <PlatformLinks platforms={game.content.platforms} />
                          </div>
                        </Tween>
                      )}
                    </div>
                    <Link to={game.full_slug} stretched />
                  </div>
                </Reveal>
              )}
            </>
          );
        })}
      </div>
    </section>
  );
};

export default SectionGamesFeatured;
