import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SocialLinks from "../Global/SocialLinks";
import Title from "../Title/Title";
import CardVacancyOverview from "../Cards/CardVacancyOverview";
import CardVacancyFeatured from "../Cards/CardVacancyFeatured";
import CardUTP from "../Cards/CardUTP";

const SectionRecruitment = (props) => {
  const { item } = props;

  return (
    <section className="py-5">
      <div className="container">
        <Title title={item.title} description={item.description} />
        <div className="row gy-4">
          <div className="col-12 col-md-6 col-lg-4">
            <CardVacancyOverview />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <CardVacancyFeatured item={item.vacancy && item.vacancy} />
          </div>
          <div className="col-12 col-lg-4">
            <CardUTP
              image={item.user_testing && item.user_testing}
              link={item.user_testing_link && item.user_testing_link}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionRecruitment;
