import React from "react";
import PropTypes from "prop-types";
import SectionNews from "./SectionNews";
import SectionForm from "./SectionForm";
import SectionGames from "./SectionGames";
import SectionGamesFeatured from "./SectionGamesFeatured";
import SectionGamesPopular from "./SectionGamesPopular";
import SectionRecruitment from "./SectionRecruitment";
import SectionQuotes from "./SectionQuotes";
import SectionPanel from "./SectionPanel";
import SectionSubmit from "./SectionSubmit";
import SectionContent from "./SectionContent";
import SectionDevelopers from "./SectionDevelopers";
import SectionItem from "./SectionItem";

const Sections = (props) => {
  const { items } = props;

  function oddEven(x) {
    return x & 1 ? "odd" : "even";
  }

  const Components = {
    panel_generic: SectionPanel,
    panel_newsletter: SectionPanel,
    panel_community: SectionPanel,
    section_news: SectionNews,
    section_form: SectionForm,
    section_games: SectionGames,
    section_games_featured: SectionGamesFeatured,
    section_games_popular: SectionGamesPopular,
    section_recruitment: SectionRecruitment,
    section_quotes: SectionQuotes,
    section_developers: SectionDevelopers,
    section_item: SectionItem,
    section_submit: SectionSubmit,
    section_content: SectionContent,
  };

  return (
    <>
      {items &&
        items.map((node, index) => {
          const item = node;

          if (typeof Components[item.component] !== "undefined") {
            const Component = Components[item.component];
            return (
              <Component item={item} key={item._uid} oddEven={oddEven(index)} />
            );
          }
        })}
    </>
  );
};

export default Sections;

Sections.propTypes = {};

Sections.defaultProps = {};
