import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import Link from "../Global/Link";
import Background from "../Global/Background";

const CardUTP = (props) => {
  const { image, link, size } = props;

  return (
    <Background
      src={image?.filename}
      sm="580x580"
      md="300x300"
      lg="470x470"
      className="brand-shadow brand-radius ratio ratio-1x1 brand-background-yellow"
    >
      <div className="d-flex flex-column justify-content-center align-items-center text-center p-5">
        <div className="mt-auto">
          <Link to={link} button icon colour="white">
            Learn more
          </Link>
        </div>
      </div>
    </Background>
  );
};

export default CardUTP;

CardUTP.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardUTP.defaultProps = {
  settings: null,
};
