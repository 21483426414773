import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SocialLinks from "../Global/SocialLinks";

const Panel = (props) => {
  const { children, image, background, text, accent, align } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div
          className={`row m-0 px-2 px-md-5 py-2 brand-background-${
            background ? background : `pink`
          } brand-text-${
            text ? text : `white`
          } brand-radius brand-shadow justify-content-center ${
            align === "center"
              ? "text-center d-flex flex-column justify-content-center"
              : "text-left"
          }`}
        >
          {image && image.filename && (
            <div
              className={`col-12 col-md-6 height-100 d-flex align-items-center justify-content-center mx-auto ${
                align === "left" ? "order-md-1" : ""
              }`}
            >
              <img
                src={image && SmartImage(image.filename, "400x")}
                class="mh-100"
                alt=""
              />
            </div>
          )}
          {children && (
            <div
              className={`col-12 ${
                image && image.filename ? "col-md-6" : "col-md-12 py-5 py-md-0"
              } height-100 d-flex align-items-center py-2 py-md-5 text-center ${
                align === "center" ? "justify-content-center mx-auto" : ""
              }`}
            >
              <div>{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;

Panel.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Panel.defaultProps = {
  settings: null,
};
