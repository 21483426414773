import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import Image from "../Global/Image";
import SmartImage from "../../utils/ImageHandler";
import Link from "../Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardQuote = (props) => {
  const { item, icon } = props;

  return (
    <div className="brand-radius brand-background-white d-flex flex-column align-items-center p-4 h-100">
      {item.position === "top" && item.image?.filename && (
        <div className="mb-3 w-100">
          <Image
            src={item.image?.filename}
            sm="400x225"
            md="400x225"
            lg="400x225"
            alt={item.name && item.name}
            className="rounded w-100"
          />
        </div>
      )}
      <div
        className={`${
          item.quotes === "true" ? "fs-5" : "fs-4"
        } fst-italic w-100 mb-3"`}
      >
        {item.quotes === "true" && (
          <FontAwesomeIcon icon="quote-left" className={`brand-text-${icon}`} />
        )}
        {item.copy && <SmartText>{item.copy}</SmartText>}
      </div>
      <div className="w-100 mt-auto d-flex align-items-center">
        {item.position === "bottom" && item.image?.filename && (
          <span className="flex-shrink-0">
            <Image
              src={item.image?.filename}
              sm="80x80"
              md="80x80"
              lg="60x60"
              alt={item.name && item.name}
              className="rounded rounded-circle"
            />
          </span>
        )}
        {item.name && (
          <p
            className={`mb-0 flex-grow-1${
              item.position === "bottom" && item.image?.filename ? " ms-3" : ""
            }`}
          >
            <strong>{item.name}</strong>
            {item.role && <>, {item.role}</>}
            {item.title && (
              <>
                <br />
                <strong className="small">{item.title}</strong>
              </>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default CardQuote;

CardQuote.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardQuote.defaultProps = {
  settings: null,
};
