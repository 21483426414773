import React, { useState } from "react";
import Link from "../Global/Link";
import { Input, Email, Checkboxes } from "../Global/Forms";
import * as Yup from "yup";

const SectionForm = (props) => {
  const { item } = props;

  const [showSuccess, setShowSuccess] = useState(false);
  const [agreeNda, setAgreeNda] = useState(false);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().min(1, "Too Short!").required("Your name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("A valid email address is required"),
    nda: Yup.boolean().oneOf(
      [true],
      "You must agree to the NDA before submitting your application"
    ),
  });

  return (
    <section className="brand-background-grey">
      <div className="container">
        <form
          className="row justify-content-center"
          data-netlify="true"
          name="mvp"
          action="/thanks/"
          method="POST"
        >
          <input type="hidden" name="form-name" value="mvp" />
          <div className="col-12">
            <div className="m-0 p-md-5 p-2 brand-radius brand-shadow brand-background-green brand-text-white mb-4 mb-md-5">
              <Input id="name" name="name" label="Your name" />
              <Email id="email" name="email" label="Your email" />
              <Input id="discord" name="discord" label="Discord username" />
              <Checkboxes
                id="type"
                label="What kind of gamer are you?"
                items={[
                  {
                    name: "vastworlds",
                    label: "I love to explore vast worlds",
                  },
                  {
                    name: "adrenaline",
                    label: "Give me a fast and frantic blast of adrenaline",
                  },
                  {
                    name: "chilled",
                    label: "I'm all about a chilled experience",
                  },
                  {
                    name: "sci-fi",
                    label: "If there's a heavy sci-fi theme, I'm there",
                  },
                  {
                    name: "vr",
                    label:
                      "If I can experience it in VR, that's how I want to play",
                  },
                  {
                    name: "solo",
                    label: "I like to play on my own",
                  },
                  {
                    name: "others",
                    label: "I like to play with others",
                  },
                  {
                    name: "co-op",
                    label: "There's no 'I' in team, co-op all the way",
                  },
                  {
                    name: "pvp",
                    label:
                      "Nothing gives me more joy than destroying my enemies (and friends) in PVP",
                  },
                  {
                    name: "sports",
                    label: "I love anything sports related",
                  },
                  {
                    name: "puzzles",
                    label:
                      "My friends call me Sherlock, because I love solving puzzles",
                  },
                  {
                    name: "action",
                    label: "I love a good slice of action with my adventure",
                  },
                  {
                    name: "survival",
                    label:
                      "Step aside Bear Grylls, i'm the true survival expert",
                  },
                  {
                    name: "farming",
                    label:
                      "Whether it's a farm, a business, a city, or a medieval fort, I like to manage the minute details",
                  },
                  {
                    name: "strategy",
                    label:
                      "I love it when a plan comes together, give me strategy any day of the week",
                  },
                  {
                    name: "freedom",
                    label: "I like the freedom to explore at my own pace",
                  },
                  {
                    name: "defined",
                    label: "I like to work my way through a defined experience",
                  },
                ]}
                description=""
              />
              <div className="row">
                <p>
                  You can unsubscribe at any time by clicking the link in the
                  footer of our emails. For information about our privacy
                  practices, please visit our website.
                </p>
                <p>
                  We use Mailchimp as our marketing platform. By clicking below
                  to subscribe, you acknowledge that your information will be
                  transferred to Mailchimp for processing. Learn more about
                  Mailchimp's privacy practices here.
                </p>
              </div>
              <fieldset className="row justify-content-center mb-3">
                <legend className="col-form-label col-sm-2 pt-0" />
                <div className="col-sm-10">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="nda"
                      type="checkbox"
                      id="nda"
                      onChange={() => setAgreeNda(agreeNda ? false : true)}
                    />
                    <label className="form-check-label" for="nda">
                      I have read and agree to the{" "}
                      <a href="/mvp-nda/" target="_blank">
                        Non-Disclosure Agreement
                      </a>
                      *
                    </label>
                  </div>
                </div>
              </fieldset>

              <div className="row justify-content-end">
                <div className="col-auto">
                  <Link
                    button="real"
                    icon="paper-plane"
                    colour="white"
                    disabled={agreeNda ? false : true}
                  >
                    Submit
                  </Link>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-auto small">*Required</div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SectionForm;
