import React from "react";
import Link from "./Link";

const Input = (props) => {
  const { id, name, label, description } = props;

  return (
    <div className="row justify-content-center mb-3">
      <label for={id} className="col-sm-2 col-form-label">
        {label}
      </label>
      <div className="col-sm-10">
        <input
          type="text"
          id={id}
          name={name}
          className="form-control brand-input brand-border-green"
          aria-describedby={`aria_${id}`}
        />
        <div id={`aria_${id}`} className="form-text">
          {description}
        </div>
      </div>
    </div>
  );
};

const Email = (props) => {
  const { id, name, label, description } = props;

  return (
    <div className="row justify-content-center mb-3">
      <label for={id} className="col-sm-2 col-form-label">
        {label}
      </label>
      <div className="col-sm-10">
        <input
          type="email"
          id={id}
          name={name}
          className="form-control brand-input brand-border-green"
          aria-describedby={`aria_${id}`}
        />
        <div id={`aria_${id}`} className="form-text">
          {description}
        </div>
      </div>
    </div>
  );
};

const Textarea = (props) => {
  const { id, name, label, description } = props;

  return (
    <div className="row justify-content-center mb-3">
      <label for={id} className="col-sm-2 col-form-label">
        {label}
      </label>
      <div className="col-sm-10">
        <textarea
          id={id}
          name={name}
          className="form-control brand-input"
          aria-describedby={`aria_${id}`}
        />
        <div id={`aria_${id}`} className="form-text">
          {description}
        </div>
      </div>
    </div>
  );
};

const Checkboxes = (props) => {
  const { id, label, description, items } = props;

  return (
    <fieldset className="row justify-content-center mb-3">
      <legend className="col-form-label col-sm-2 pt-0">{label}</legend>
      <div className="col-sm-10">
        {items.map((item, index) => {
          return (
            <>
              {item && (
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    name={item.name}
                    type="checkbox"
                    id={item.name}
                  />
                  <label className="form-check-label" for={item.name}>
                    {item.label}
                  </label>
                </div>
              )}
            </>
          );
        })}
      </div>
    </fieldset>
  );
};

const Select = (props) => {
  const { id, name, label, description, items } = props;

  return (
    <div className="row justify-content-center mb-3">
      <label for={id} className="col-sm-2 col-form-label">
        {label}
      </label>
      <div className="col-sm-10">
        <select
          className="form-select form-control brand-input brand-input-white"
          aria-label={label}
          aria-describedby={`aria_${id}`}
          id={id}
          name={name}
        >
          <option selected disabled hidden>
            Select
          </option>
          {items.map((item, index) => {
            return (
              <>{item && <option value={item.value}>{item.label}</option>}</>
            );
          })}
        </select>
        <div id={`aria_${id}`} className="form-text">
          {description}
        </div>
      </div>
    </div>
  );
};

export { Input, Email, Textarea, Checkboxes, Select };
