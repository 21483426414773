import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SmartColour from "../../utils/ColourHandler";
import Link from "../Global/Link";
import Title from "../Title/Title";
import Modal, { ModalBody, ModalFooter } from "../Templates/Modal";

const SectionDevelopers = (props) => {
  const { item } = props;

  const colour = SmartColour(item.colour);

  const modalRefTop = useRef();
  const modalRefRight = useRef();
  const modalRefBottom = useRef();
  const modalRefLeft = useRef();

  return (
    <>
      <section className="mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div
                className="w-100 ratio ratio-7x10 brand-background-cover"
                style={{
                  backgroundImage: `url(${SmartImage(
                    item.background.filename
                  )})`,
                }}
              >
                <div>
                  <span className="position-absolute top-0 start-0 translate-middle-y brand-mecha-button brand-mecha-button-top-left">
                    <Link
                      button
                      icon="plus"
                      className="brand-background-white"
                      colour="green"
                      onClick={() => modalRefTop.current.openModal()}
                    >
                      {item.head_title && item.head_title}
                    </Link>
                  </span>
                  <span className="position-absolute top-0 end-0 translate-middle-y brand-mecha-button brand-mecha-button-top-right">
                    <Link
                      button
                      icon="plus"
                      className="brand-background-white"
                      colour="green"
                      onClick={() => modalRefRight.current.openModal()}
                    >
                      {item.right_title && item.right_title}
                    </Link>
                  </span>
                  <span className="position-absolute bottom-0 start-0 brand-mecha-button brand-mecha-button-bottom-left">
                    <Link
                      button
                      icon="plus"
                      className="brand-background-white"
                      colour="green"
                      onClick={() => modalRefLeft.current.openModal()}
                    >
                      {item.left_title && item.left_title}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal ref={modalRefTop} title={item.head_title && item.head_title}>
        <ModalBody>
          {item.head_body && <SmartText>{item.head_body}</SmartText>}
        </ModalBody>
      </Modal>
      <Modal ref={modalRefRight} title={item.right_title && item.right_title}>
        <ModalBody>
          {item.right_body && <SmartText>{item.right_body}</SmartText>}
        </ModalBody>
      </Modal>
      <Modal ref={modalRefBottom} title={item.foot_title && item.foot_title}>
        <ModalBody>
          {item.foot_body && <SmartText>{item.foot_body}</SmartText>}
        </ModalBody>
      </Modal>
      <Modal ref={modalRefLeft} title={item.left_title && item.left_title}>
        <ModalBody>
          {item.left_body && <SmartText>{item.left_body}</SmartText>}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SectionDevelopers;
//{JSON.stringify(item)}
