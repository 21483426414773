import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import Link from "../Global/Link";
import SmartImage from "../../utils/ImageHandler";
import SmartColour from "../../utils/ColourHandler";
import SocialLinks from "../Global/SocialLinks";
import Panel from "../Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionPanel = (props) => {
  const { item } = props;

  const colour = SmartColour(item && item.colour);

  let siteSettings = null;

  const siteData = useStaticQuery(graphql`
    {
      storyblokEntry(slug: { eq: "settings" }) {
        content
      }
    }
  `);

  siteSettings = JSON.parse(siteData.storyblokEntry.content);

  return (
    <>
      {item && (
        <section className="py-3 py-md-5">
          <div className="container">
            <Panel
              background={colour && colour.background}
              text={colour && colour.text}
              accent={colour && colour.accent}
              align={item.align}
              image={item.image}
            >
              {item.title && <h3 className="brand-font">{item.title}</h3>}
              {item.body && <SmartText>{item.body}</SmartText>}
              {item.component === "panel_newsletter" && (
                <form
                  className="row justify-content-center"
                  data-netlify="true"
                  name="mailinglist"
                  action="/thanks/"
                  method="POST"
                >
                  <input type="hidden" name="form-name" value="mailinglist" />
                  <div className="col-auto my-1">
                    <label for="inputEmail" className="visually-hidden">
                      Email address
                    </label>
                    <input
                      type="input"
                      name="email"
                      className="form-control brand-input brand-input-white h-100"
                      id="inputEmail"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="col-auto my-1">
                    <Link
                      button="real"
                      icon="paper-plane"
                      colour={colour && colour.text}
                    >
                      Subscribe
                    </Link>
                  </div>
                </form>
              )}
              {item.component === "panel_generic" && (
                <>
                  {item.button && (
                    <p>
                      <Link
                        to={item.link}
                        icon
                        button
                        colour={colour && colour.text}
                      >
                        {item.button}
                      </Link>
                    </p>
                  )}
                </>
              )}
              {item.component === "panel_community" && (
                <ul className="list-unstyled m-0 fs-1">
                  {siteSettings.discord && (
                    <li className="list-inline-item mx-3">
                      <Link to={siteSettings.discord}>
                        <FontAwesomeIcon icon={["fab", "discord"]} />
                      </Link>
                    </li>
                  )}
                  {siteSettings.youtube && (
                    <li className="list-inline-item mx-3">
                      <Link to={siteSettings.youtube}>
                        <FontAwesomeIcon icon={["fab", "youtube"]} />
                      </Link>
                    </li>
                  )}
                  {siteSettings.instagram && (
                    <li className="list-inline-item mx-3">
                      <Link to={siteSettings.instagram}>
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                      </Link>
                    </li>
                  )}
                  {siteSettings.facebook && (
                    <li className="list-inline-item mx-3">
                      <Link to={siteSettings.facebook}>
                        <FontAwesomeIcon icon={["fab", "facebook"]} />
                      </Link>
                    </li>
                  )}
                  {siteSettings.twitter && (
                    <li className="list-inline-item mx-3">
                      <Link to={siteSettings.twitter}>
                        <FontAwesomeIcon icon={["fab", "twitter"]} />
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </Panel>
          </div>
        </section>
      )}
    </>
  );
};

export default SectionPanel;
