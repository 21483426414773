import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import Link from "../Global/Link";

const CardVacancyOverview = (props) => {
  const jobPosts = useStaticQuery(graphql`
    {
      jobs: allStoryblokEntry(filter: { field_component: { in: ["career"] } }) {
        nodes {
          full_slug
        }
      }
    }
  `);
  let jobs;
  if (jobPosts) {
    jobs = jobPosts.jobs;
  }

  return (
    <div className="brand-shadow brand-radius ratio ratio-1x1">
      <div className="d-flex flex-column justify-content-center align-items-center text-center p-5">
        <div className="mt-auto ">
          <div className="brand-font brand-font-huge brand-text-pink">
            {jobs ? Object.keys(jobs.nodes).length : "0"}
          </div>
          <div>vacancies</div>
        </div>
        <div className="mt-auto">
          <Link to={`/careers/`} button icon>
            All roles
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardVacancyOverview;
