export default function SmartColour(colour) {
  let response = {
    background: "white",
    text: "black",
    accent: "pink",
  };
  // We need to respond with
  // - Background colour
  // - Font colour
  // - Accent colour

  if (colour) {
    if (colour === "pink") {
      response = {
        background: "pink",
        text: "white",
        accent: "white",
      };
    } else if (colour === "green") {
      response = {
        background: "green",
        text: "white",
        accent: "white",
      };
    } else if (colour === "yellow") {
      response = {
        background: "yellow",
        text: "black",
        accent: "black",
      };
    } else if (colour === "black") {
      response = {
        background: "black",
        text: "white",
        accent: "white",
      };
    } else if (colour === "white") {
      response = {
        background: "white",
        text: "black",
        accent: "pink",
      };
    }

    return response;
  }
  return null;
}
