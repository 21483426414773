import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import Title from "../Title/Title";

import Link from "../Global/Link";
import SmartImage from "../../utils/ImageHandler";
import SmartColour from "../../utils/ColourHandler";
import SocialLinks from "../Global/SocialLinks";
import Panel from "../Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionContent = (props) => {
  const { item } = props;

  return (
    <>
      {item && (
        <section className="py-5">
          <div className="container">
            {item.title && (
              <Title
                title={item.title && item.title}
                link={item.link && item.link}
                button={item.button && item.button}
                text="black"
              />
            )}
            {item.body && (
              <div className="row">
                <SmartText>{item.body}</SmartText>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default SectionContent;
