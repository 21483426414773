import React, { useState } from "react";
import Link from "../Global/Link";
import { Input, Email, Textarea, Checkboxes } from "../Global/Forms";

const SectionSubmit = (props) => {
  const { item } = props;

  const [step, setStep] = useState(1);
  const stepTotal = 3;

  function nextStep() {
    if (step < stepTotal) {
      setStep(step + 1);
    }
  }
  function prevStep() {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  return (
    <>
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-center mb-5 d-none">
            <div className="col-12 col-lg-8">
              <div className="progress">
                <div
                  className={`progress-bar w-${step * 25}`}
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>
          <form
            className="row justify-content-center"
            data-netlify="true"
            name="developers"
            action="/thanks/"
            method="POST"
          >
            <input type="hidden" name="form-name" value="developers" />
            <div className="col-12 col-lg-8">
              <h3 className="brand-font text-center mb-5">About you</h3>
              <Input id="name" name="name" label="Your name" />

              <Email id="email" name="email" label="Your email" />

              <h3 className="brand-font text-center mb-5">About your game</h3>
              <Input id="game" name="game" label="Game name" />
              <Textarea
                id="pitch"
                name="pitch"
                label="Pitch"
                description="Give us the elevator pitch... What makes your game different? Why will we love your game as much as you do?"
              />
              <Input
                id="document"
                name="doc"
                label="Document"
                description="Send us a link to your Game Design Document and Development Timeline so we can understand your game in even more detail."
              />
              <Input
                id="demo"
                name="demo"
                label="Demo"
                description="Share a link to a build of your game so we can take a look. You can also share a link to a video showing gameplay."
              />
              <Input id="website" name="website" label="Website" />

              <h3 className="brand-font text-center mb-5">
                What are you looking for?
              </h3>

              <Checkboxes
                id="support"
                label="Support"
                items={[
                  {
                    name: "distribution",
                    label: "Distribution",
                  },
                  {
                    name: "marketing",
                    label: "Marketing",
                  },
                  {
                    name: "QA",
                    label: "QA",
                  },
                  {
                    name: "financial",
                    label: "Financial",
                  },
                  {
                    name: "other",
                    label: "Other",
                  },
                ]}
                description="We offer a range of services to developers. What are you interested in us helping you with?"
              />

              <div className="row justify-content-center">
                <div className="col-auto">
                  <Link button="real" icon="paper-plane">
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SectionSubmit;
