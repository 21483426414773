import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SmartTag from "../../utils/TagHandler";
import Link from "../Global/Link";

const CardVacancyFeatured = (props) => {
  const { item, size } = props;

  const doc = item.content;

  if (!doc) return null;

  return (
    <div className="brand-shadow brand-radius ratio ratio-1x1">
      <div className="d-flex flex-column justify-content-center align-items-center text-center p-5">
        <div className="mt-auto">
          <div>
            <span className="badge brand-background-pink">Featured role</span>
          </div>
          {doc.title && <h3 className="py-3 brand-font">{doc.title}</h3>}
          {doc.department && (
            <div className="border-top py-2">{doc.department}</div>
          )}
          {doc.location && (
            <div className="border-top py-2">{doc.location}</div>
          )}
          {doc.type && (
            <div className="border-top py-2">{SmartTag(doc.type)}</div>
          )}
        </div>
        <div className="mt-auto">
          <Link to={item.full_slug} button icon>
            More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardVacancyFeatured;

CardVacancyFeatured.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardVacancyFeatured.defaultProps = {
  settings: null,
};
